export const USMappingSizes = {
    1: '5/0',
    1.25: '4/0',
    1.5: '000',
    1.75: '00',
    2: `0`,
    2.25: 1,
    2.5: 1.5,
    2.75: 2,
    3: 2.5,
    3.25: 3,
    3.5: 4,
    3.75: 5,
    4: 6,
    4.5: 7,
    5: 8,
    5.5: 9,
    6: 10,
    6.5: 10.5,
    7: 10.5,
    7.5: 11,
    8: 11,
    9: 13,
    10: 15,
    12: 17,
    15: 19,
    16: 19,
    19: 35,
    20: 36,
    25: 50,
    30: 65,
    Other: 'Other',
};

export const NEEDLE_SIZES = {
    metric: [
        1,
        1.25,
        1.5,
        1.75,
        2,
        2.25,
        2.5,
        2.75,
        3,
        3.25,
        3.5,
        3.75,
        4,
        4.5,
        5,
        5.5,
        6,
        6.5,
        7,
        7.5,
        8,
        9,
        10,
        12,
        16,
        19,
        25,
        'Other',
    ],
    imperial: [
        '5/0',
        '4/0',
        '000',
        '00',
        0,
        1,
        1.5,
        2,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        10.5,
        10.5,
        10.5,
        11,
        13,
        14,
        15,
        17,
        19,
        50,
        'Other',
    ],
};
export const GAUGE_SIZE = {
    metric: [
        '8/10',
        '9/10',
        '10/10',
        '11/10',
        '12/10',
        '13/10',
        '14/10',
        '15/10',
        '16/10',
        '17/10',
        '18/10',
        '19/10',
        '20/10',
        '21/10',
        '22/10',
        '23/10',
        '24/10',
        '25/10',
        '26/10',
        '27/10',
        '28/10',
        '29/10',
        '30/10',
        '31/10',
        '32/10',
        '33/10',
        '34/10',
        '35/10',
        '36/10',
        '37/10',
        '38/10',
        '39/10',
        '40/10',
        'Other',
        'None',
    ],
    imperial: [
        '8/4"',
        '9/4"',
        '10/4"',
        '11/4"',
        '12/4"',
        '13/4"',
        '14/4"',
        '15/4"',
        '16/4"',
        '17/4"',
        '18/4"',
        '19/4"',
        '20/4"',
        '21/4"',
        '22/4"',
        '23/4"',
        '24/4"',
        '25/4"',
        '26/4"',
        '27/4"',
        '28/4"',
        '29/4"',
        '30/4"',
        '31/4"',
        '32/4"',
        '33/4"',
        '34/4"',
        '35/4"',
        '36/4"',
        '37/4"',
        '38/4"',
        '39/4"',
        '40/4"',
        'Other',
        'None',
    ],
};

export const ProjectEndpoints = {
    update: 'project/update',
};

export const ALL_YEARS = 'all';
