const noTranslations = {
    // 'Welcome to our digital knitting universe.':
    //     'Velkommen til vårt digitale strikkeunivers.',
    // "So great YOU're in!": 'Så gøy at DU er med!',
    'terms and conditions': 'betingelser og vilkår',
    'Privacy Policy': 'Personvernregler',
    'I accept the': 'Jeg aksepterer',
    Profile: 'Profil',
    // 'Log out': 'Logg ut',
    'Log in with': 'Logg inn med',
    'Log in': 'Logg inn',
    Or: 'Eller',
    Login: 'Logg inn',
    Email: 'E-post',
    Password: 'Passord',
    Register: 'Registering',
    // 'Register with': 'Registrer deg med',
    // 'Repeat password': 'Gjenta passord',
    // 'Create account': 'Opprett bruker',
    'Create new account': 'Opprett ny bruker',
    'is not a valid email address': 'er ikke en gyldig e-postadresse',
    // 'must match the password': 'må matche passordet',
    'Accepting the terms is required for registration.':
        'Å godta vilkårene er nødvendig for å registrere deg.',
    'Forgot password': 'Glemt passord',
    'Reset password': 'Tilbakestille passord',
    // 'Choose a new password and confirm it below':
    //     'Velg et nytt passord og bekreft det nedenfor',
    'Your reset token is invalid. Please try resetting your password again':
        'Din tilbakestillingskode er ugyldig. Vennligst prøv å tilbakestille passordet på nytt',
    'Save new password': 'Lagre nytt passord',
    // 'Already have an account?': 'Har du allerede en konto?',
    'Active projects': 'Aktive prosjekter',
    'No active projects': 'Ingen aktive prosjekter',
    // 'There are no notes for this month yet. Tap to write notes.':
    // 'Det er ingen notater for denne måneden. Trykk for å skrive notater.',
    'Forgot password?': 'Glemt passordet?',
    // 'Just write your email address and we will email you a password reset link that will allow you to choose a new one.':
    //     'Bare skriv e-postadressen din, så sender vi deg en lenke for tilbakestilling av passordet som lar deg velge en ny.',
    'Page not found': 'Side ikke funnet',
    'The page requested does not exist':
        'Den forespurte siden eksisterer ikke.',
    'Click here to go back': 'Klikk her for å gå tilbake',
    Projects: 'Prosjekter',
    'No projects': 'Ingen prosjekter',
    'New project': 'Nytt prosjekt',
    Pattern: 'Oppskrift',
    Yarns: 'Garn',
    Yarn: 'Garn',
    yarn: 'garn',
    search: 'Søk..',
    'Search..': 'Søk..',
    'The email has already been taken.':
        'Denne e-postadressen er allerede brukt',
    'The provided credentials do not match our records.':
        'De oppgitte legitimasjonene samsvarer ikke med registrene våre.',
    // 'There is no account for this email':
    //     'Det er ingen konto for denne e-posten',
    'We have sent you a password reset email':
        'Vi har sendt deg en e-post med tilbakestilling av passord',
    Patterns: 'Oppskrifter',
    patterns: 'oppskrifter',
    'My journal': 'Min journal',
    Calendar: 'Kalender',
    'No notes yet. Tap here to add notes':
        'Ingen notater ennå. Trykk her for å legge til notater',
    Notes: 'Notater',
    'Project name': 'Prosjektnavn',
    For: 'Til',
    Size: 'Størrelse',
    'Attach pattern': 'Legg til oppskrift',
    'Add yarn': 'Legg til garn',
    Started: 'Startet',
    Completed: 'Fullført',
    Complete: 'Fullfør',
    'Complete now': 'Fullfør nå',
    'Complete project': 'Fullfør prosjekt',
    'There are no notes on this project yet. Tap to write notes.':
        'Det er ingen notater om dette prosjektet ennå. Trykk for å skrive notater.',
    'Here you can write your thoughts about the project':
        'Her kan du skrive tankene dine om prosjektet',
    'Save notes': 'Lagre notater',
    'Save project': 'Lagre prosjekt',
    'New yarn': 'Nytt garn',
    Name: 'Navn',
    Color: 'Farge',
    Code: 'Partinummer',
    Quantity: 'Antall',
    Weight: 'Vekt',
    'Save yarn': 'Lagre garn',
    'is a required field': 'er et obligatorisk felt',
    'Add new pattern': 'Legg til ny oppskrift',
    'Add new project': 'Legg til nytt prosjekt',
    'New Pattern': 'Ny oppskrift',
    Choose: 'Velge',
    New: 'Ny',
    'New pattern category': 'Ny oppskriftskategori',
    'Pattern category': 'Oppskriftskategori',
    Icon: 'Ikon',
    Change: 'Endre',
    'Change icon': 'Endre ikon',
    'Add category': 'Legg til kategori',
    'Add journal': 'Legg til journal',
    'Creating journal': 'Opprette journal',
    'You must select an icon': 'Du må velge et ikon',
    Social: 'Sosial',
    'The social feed will be available at a later point.':
        'Den sosiale strømmen vil være tilgjengelig på et senere tidspunkt',
    'No patterns in this category yet.':
        'Ingen oppskrifter i denne kategorien ennå.',
    'Click to add a new pattern': 'Klikk for å legge til en ny oppskrift',
    "You don't have any patterns uploaded":
        'Du har ikke lastet opp noen oppskrifter',
    'Select category': 'Velg kategori',
    Category: 'Kategori',
    'Select pattern': 'Velg oppskrift',
    'Upload pdf': 'Last opp pdf',
    'Replace pdf': 'Bytt pdf',
    Tags: 'Tagger',
    'Tags, such as designer name, woman, boy, gauge etc':
        'Tags, f.eks: navn på designer, dame, barn, strikkefasthet osv.',
    'Name of the pattern': 'Oppskriftsnavn',
    'Uploading a pdf is requried for adding a pattern':
        'Det er nødvendig å laste opp en pdf for å legge til en oppskrift',
    Save: 'Lagre',
    'Save pattern': 'Lagre oppskrift',
    'Choose icon': 'Velg ikon',
    'Set project as completed?': 'Sett prosjektet som fullført?',
    'Setting the project to completed is a irreversable action. Would you like to continue?':
        'Å sette prosjektet til fullført kan ikke omgjøres. Vil du fortsette?',
    Yes: 'Ja',
    No: 'Nei',
    'You have registered successfully. Please login to continue.':
        'Registreringen var vellykket. Vennligst Logg inn for å fortsette.',
    'An unexpected error happened': 'Det oppstod en uventet feil',
    'Project sucessfully added': 'Prosjektet er vellykket lagt til',
    'Facebook login': 'Facebook-pålogging',
    'We will only request your email and name from Facebook, required for login.':
        'Vi vil bare be om e-post og navn fra Facebook, som kreves for pålogging.',
    'Terms & conditions': 'Betingelser og vilkår',
    'I agree and want to register': 'Jeg er enig og vil registrere meg',
    'You are already an user of the Knitandnote application with your email address. You can':
        'Du er allerede bruker av Knitandnote-applikasjonen med e-postadressen din. Du kan',
    'Login with facebook': 'Logg inn med Facebook',
    'Login with email': 'Logg inn med e-post',
    'You have successfully logged in as ': 'Du har logget inn som ',
    or: 'eller',
    'Allow from facebook': 'Tillat fra facebook',
    'An unexpected error happened. Please refresh the page and tell us about it if it happens again.':
        'Det oppstod en uventet feil. Oppdater siden og fortell oss om den skjer igjen.',
    'Facebook login rejected': 'Facebook-pålogging avvist',
    'Editing pattern': 'Redigeringsoppskrift',
    'An unexpected error happened.': 'Det oppstod en uventet feil.',
    Edit: 'Rediger',
    Delete: 'Slett',
    'Delete pattern': 'Slett oppskrift',
    'Are you sure you want to delete': 'Er du sikker på at du vil slette',
    pattern: 'oppskrift',
    Months: 'Måneder',
    'Password successfully updated.': 'Passordet er oppdatert.',
    'No results for ': 'Ingen resultater for ',
    'Searching through': 'Søker gjennom',
    Categories: 'Kategorier',
    'Search for': 'Søk etter',
    To: 'Til',
    'Size.': 'Str.',
    'Add new': 'Legg til',
    'My project': 'Mitt prosjekt',
    'Write here': 'Skriv her',
    'Editing yarn': 'Redigering av garn',
    Create: 'Opprett',
    Firmness: 'Fasthet',
    'the project': 'prosjektet',
    'Knitting planner': 'Strikkeplaner',
    'Start project': 'Start prosjekt',
    'Plan project': 'Planlegg prosjekt',
    'Yarn stash': 'Garnlager',
    'Needle stash': 'Pinnelager',
    'Size (mm)': 'Pinnestr. (mm)',
    'Length (cm)': 'Lengde (cm)',
    Length: 'Lengde',
    'Add needle': 'Legg til pinne',
    'Save needle': 'Lagre pinne',
    Required: 'Påkrevd',
    'There has been an unexpected error': 'Det har oppstått en uventet feil',
    Brand: 'Merke',
    'Grams per skein': 'Gram per nøste',
    Grams: 'Gram',
    grams: 'gram',
    'Add new yarn': 'Legg til garn',
    'New color': 'Ny farge',
    'Choose color': 'Velg farge',
    'Delete yarn': 'Slett garn',
    'Yarn color': 'Garnfarge',
    'Yarn type': 'Garntype',
    'Welcome to the betatesting of Knit&Note!.':
        'Velkommen til betatesting av Knit&note!',
    'Enter the code below to get access to testing':
        'Tast inn koden din nedenfor, og kom i gang med testingen',
    'Only pdf files of up to {x} can be uploaded':
        'Kun pdf-filer på opptil {x} kan lastes opp',
    'Only pdf files can be uploaded. Uploaded file has extension .[{{exts}}]':
        'Kun pdf-filer kan lastes opp. Opplastede filer har utvidelse .[{{exts}}]',
    'Only images can be uploaded. Uploaded files have extension .[{{exts}}]':
        'Kun bilder kan lastes opp. Opplastede filer har utvidelse .[{{exts}}]',
    'Only images can be uploaded. Uploaded file has extension .[{{exts}}]':
        'Kun bilder kan lastes opp. Opplastet fil har utvidelse .[{{exts}}]',
    'Replace Pdf': 'Bytt ut Pdf',
    'Selecting a category is required for adding a pattern':
        'Du må velge en kategori for å legge til en oppskrift',
    'Pdf name is required': 'Filen må ha et navn',
    'Delete category': 'Slett kategori',
    category: 'kategori',
    'Delete journal': 'Slett notat',
    'Editing journal': 'Rediger journal',
    'Editing category': 'Rediger kategori',
    'Add pictures': 'Legg til bilder',
    'Delete project photo': 'Slett prosjektbilde',
    'Are you sure you want to delete this photo?':
        'Er du sikker på at du vil slette dette bildet?',
    'pin size': 'pinnestr.',
    'Needle size': 'Pinnestørrelse',
    length: 'lengde',
    'No yarns found in stash.': 'Ingen garn funnet på lager.',
    'Add pattern': 'Legg til oppskrift',
    Gauge: 'Strikkefasthet',
    'Are you sure you want to complete the project':
        'Er du sikker på at du vil fullføre prosjektet',
    'Download on': 'Last ned app til',
    'Click on': 'Trykk på',
    'and choose': 'og velg',
    'Add to home screen': 'Lagre på hjem-skjerm',
    'You can download this app on your phone':
        'Du kan laste ned denne appen på telefonen',
    Download: 'Last ned',
    Downloading: 'Laster ned',
    'The app is being downloaded in the background. It will be available on your homescreen as soon as that is complete':
        'Appen lastes ned i bakgrunnen. Den vil være tilgjengelig på startskjermen så snart den er fullført',
    Installed: 'Installert',
    'is now installed and can be accessed from your home screen':
        'er nå installert og kan nås fra startskjermen',
    'Set as main': 'Sett som hoved',
    'completed projects': 'fullførte prosjekt',
    'Completed projects': 'Fullførte prosjekt',
    'You have completed {{projectsCount}} projects this year':
        'Du har fullført {{projectsCount}} prosjekter i år',
    'You have used {{yarnsUsed}} skeins this year':
        'Du har brukt {{yarnsUsed}} nøster i år',
    'You have used {{weight}} {{weightLabel}} of yarn this year':
        'Du har brukt {{weight}} {{weightLabel}} garn i år',
    'You have used {{meters}} meters of yarn this year':
        'Du har brukt {{meters}} meter garn i år',
    'Save changes': 'Lagre endringer',
    Membership: 'Medlemskap',
    'Email notifications': 'E-postvarsler',
    Privacy: 'Personvern',
    'Privacy policy': 'Personvernerklæring',
    'Delete account': 'Slett konto',
    'Delete everything connected to your user':
        'Slett alt som er koblet til brukeren din',
    'Please contact us if you wish to delete everything connected to your user':
        'Ta kontakt med oss hvis du vil slette alt som er knyttet til brukeren din',
    'I need some time off!': 'Jeg trenger litt fri!',
    'dd.mm.yyy': 'dd.mm.åååå',
    'Not yet': 'Ikke ennå',
    'Invite friends': 'Inviter venner',
    'Copy invite link': 'Kopier invitasjonslenke',
    'Share on SMS, in messenger, on your Instagram profile or wherever you would like.':
        'Del på SMS, i messenger, på din Instagramprofil eller andre steder.',
    '{{numberOfFriends}} of your friends have signed up':
        'Du har vervet {{numberOfFriends}} venner',
    'You have {{totalWeight}} {{weightLabel}}':
        'Du har {{totalWeight}} {{weightLabel}}',
    'of yarn in stash': 'garn på lager',
    Congratulations: 'Gratulerer',
    'for a successful project': 'med vel gjennomført prosjekt',
    'Well done': 'Godt jobba',
    'Why not share the result of your completed project in the feed with your knitting friends?':
        'Hvorfor ikke dele resultatet av det fullførte prosjektet i feeden med dine strikkevenner?',
    'Start a new project': 'Start nytt prosjekt',
    'Go to': 'Gå til',
    'What is new in this update?': 'Hva er nytt i denne oppdateringen?',
    'Regards from the Knit&Note team': 'Hilsen fra team Knit&Note',
    'App version': 'App -versjon',
    Welcome: 'Velkommen',
    'We are so happy to see you here': 'Vi er så glade for å se deg her',
    'Add your coupon code under and go further for registering or logging in':
        'Legg inn din kode nedenfor og gå videre for å registrere bruker eller logge inn',
    'Coupon code': 'Kupongkode',
    'Your coupon code here': 'Din kupongkode her',
    'Check coupon': 'Sjekk kupongen',
    'No coupon code': 'Ingen kupongkode',
    'Your active code is': 'Din aktive kode er',
    'This coupon code has already been used':
        'Denne kupongkoden er allerede brukt',
    'The coupon code is not valid': 'Kupongkoden er ikke gyldig',
    'Congratulations! You get 3 months of free usage':
        'Gratulerer! Du får 3 måneders gratis bruk',
    English: 'Engelsk',
    "Let's get started": 'La oss komme i gang',
    'Please select your preferred language': 'Velg ønsket språk',
    'More languages will be added in the future':
        'Flere språk vil bli lagt til i fremtiden',
    Next: 'Neste',
    'to your personal knitting universe': 'til ditt personlige strikkeunivers',
    'We are so happy to see you here! You are now in the company of knitters from all around the globe, loving to create, inspire and be inspired.':
        'Vi er så glade for å se deg her! Du er nå sammen med strikkere fra hele verden, som elsker å skape, inspirere og bli inspirert.',
    'Always  available on your phone, tablet or computer.':
        'Alltid tilgjengelig for deg på telefon, nettbrett eller PC.',
    'Together for a sustainable future':
        'Sammen skaper vi en bærekraftig fremtid',
    'Knit&note is powered by': 'Knit&note er drevet av',
    '100% renewable energy': '100% fornybar energi',
    'so by using knit&note you are contributing to a sustainable future':
        'så når du lagrer oppskriftene og notatene dine i knit&note bidrar du til en bærekraftig fremtid',
    'Knitting is also beneficial for your health, so enjoy knitting and knit&note':
        'Strikking er også godt for helsen, så nyt strikkingen og knit&note',
    'It’s good for you and the climate': 'Bra for deg og bra for klima',
    'Get started': 'Kom i gang',
    'knit&note is powered by': 'knit&note er drevet av',
    'Get from yarn stash': 'Hent fra garnlager',
    meters: 'meter',
    'Weight of yarn used': 'Vekt garn brukt',
    'Skeins for this project': 'Antall nøster til prosjektet',
    Language: 'Språk',
    'Click here to add': 'Klikk her for å legge til',
    'Add your coupon code below': 'Legg til kupongkoden din nedenfor',
    'Free until': 'Gratis til',
    'free until': 'gratis til',
    Continue: 'Fortsett',
    'Add and choose another yarn': 'Legg til og velg enda et garn',
    'When you add yarn from the yarn stash, the same is removed from the warehouse.':
        'Når du legger til garn fra garnlageret fjernes tilsvarende fra lageret.',
    'If you use smaller yarns, you can change the number of grams before you complete the project, and the yarn residue will be returned to the yarn stash.':
        'Om du benytter mindre garn kan du endre antall gram før du fullfører prosjektet, så blir garnresten tilbakeført til garnlageret.',
    'Add and add another color of the same yarn':
        'Legg til og velg ny farge i samme garn',
    Blend: 'Innhold',
    'Edit yarn': 'Rediger garn',
    'Are you sure you would like to delete the yarn':
        'Er du sikker på at du vil slette garnet',
    '{{grams}} grams of {{yarnFullName}} will be returned to stash':
        '{{grams}} gram {{yarnFullName}} blir tilbakeført lageret',
    'Would you like to return {{grams}} grams of {{yarnFullName}} to the yarn stash?':
        'Vil du at {{grams}} gram {{yarnFullName}} skal føres tilbake til garnlageret?',
    Upgrade: 'Oppgrader',
    'You have added more grams of yarn than you have in stash. Are you sure you want to continue?':
        'Du har lagt til flere gram garn enn du har på lager. Er du sikker på at du vil fortsette?',
    'Payment successful': 'Betaling vellykket!',
    'Thank you! You now have access to all premium features.':
        'Tusen takk! Nå har du tilgang til all premiumfunksjonalitet.',
    'Now let’s get back to knitting': 'Nå, la oss fortsette med strikkingen',
    'Back to home': 'Tilbake til hjem',
    'With love from': 'Med vennlig hilsen',
    'CEO and founder': 'CEO og grunnlegger',
    'Premium feature': 'Premiumfunksjon',
    Annual: 'Årlig',
    Monthly: 'Månedlig',
    'Per month': 'Per måned',
    Subscribe: 'Abonner',
    'You need to upgrade to {{membershipLabel}} to be able to upload {{picturesToUpload}} pictures on the project':
        'Du må oppgradere til {{membershipLabel}} for å kunne laste opp {{picturesToUpload}} bilder på prosjektet',
    'Popular - save {{percentage}}% by paying annually':
        'Populær - spar {{percentage}}% ved å betale årlig',
    Recommended: 'Anbefalt',
    'Add 10 pictures in your projects': 'Legg til 10 bilder i prosjektene dine',
    'Tools (coming)': 'Verktøy (kommer)',
    'Unlimited project photos': 'Ubegrenset prosjektbilder',
    Subscriptions: 'Abonnementer',
    Subscription: 'Abonnement',
    'Frequently asked questions': 'Vanlige spørsmål',
    'Is there any lock in period?': 'Er det noen bindingstid?',
    'No. You can change your subscription at any time. If you change down the change will take effect when your next payment is due.':
        'Nei, hvis du vil endre abonnement kan du når som helst gjøre dette. Om du endrer fra premium eller bidragstyer trer endringen i kraft fra neste betalingsforfall.',
    'What happens if i downgrade from a premium subscription?':
        'Hva skjer hvis jeg nedgraderer fra et premium abonnement?',
    'You will keep access to all features, and everything you have added, but you´ll get ads while using Knit&Note. You will also lose other benefits premium users might have.':
        'Du beholder tilgang til alle funksjoner, men får reklame når du bruker tjenesten. Du mister også andre fordeler premiumbrukerne eventuelt har.',
    'You can still see all the pictures from your previous projects, but you’ll be limited to two photos in your new projects.':
        'Du kan fortsatt se alle bilder i tidligere prosjekt, men det blir en begrensning på 2 bilder per nye prosjekt.',
    'You will also lose access to the other premium features like yarn stash and needle stash.':
        'Du mister også tilgang til andre premium funksjoner, som garn- og pinnelager.',
    'What happens to everything if I lose my phone?':
        'Hva skjer om jeg mister telefonen?',
    'Your personal knitting universe is all saved in the cloud (powered by 100% renewable energy).':
        'Ditt personlige strikkeunivers ligger lagret i skyen (drevet av 100% fornybar energi).',
    'You can access all your patterns, projects and notes at any time from any device at {{url}}. So if you have forgotten your phone, simply log in throguh a friends browser and you can show of your projects or have a look at the pattern you needed.':
        'Du kan når som helst logge deg inn via nettleser på {{url}} for å få tilgang til hele ditt univers. ',
    'Manage subscription': 'Administrer abonnement',
    Manage: 'Administrer',
    'You can change, pause or cancel your subscription by hitting the Manage subscription button above':
        'Du kan endre, sette på pause eller kansellere abonnementet ditt ved å trykke på Administrer abonnement-knappen ovenfor',
    'You will be able to select another subscription type after your free premium has expired':
        'Du vil kunne velge en annen abonnementstype etter at gratispremien din har utløpt',
    'You now have 30 days free trial to really test it out.':
        'Du får nå 30 dagers fri bruk til å prøve det skikkelig ut.',
    'After the trial you decide if you would like to continue with our free or premium subscription.':
        'Så bestemmer du selv om du vil fortsette med gratis eller premium abonnement i etterkant.',
    'I hope you’ll enjoy knit&note': 'Håper du får masse glede av knit&note!',
    Love: 'Mvh.',
    'Available until': 'Tilgjengelig frem til',
    'Select subscription': 'Velg abonnement',
    'Your trial is ending today': 'Prøveperioden din avsluttes i dag',
    'Your free premium is ending today': 'Din gratis Premium avsluttes i dag',
    and: 'og',
    'we hope you have enjoyed it': 'vi håper du har hatt glede av den',
    'To continue with access to all premium features, please subscribe to Premium':
        'Fortsett med premium abonnement for å beholde tilgang til alle funksjoner',
    Selected: 'Valgt',
    'By choosing Basic, you will lose access to Premium features from tomorrow':
        'Ved å velge Basic mister du tilgang til Premium-funksjoner fra i morgen',
    'Are you sure you would like to continue?':
        'Er du sikker på at du vil fortsette?',
    'Google login rejected': 'Google-pålogging avvist',
    'Welcome to your personal knitting universe.':
        'Velkommen til ditt personlige strikkeunivers.',
    'Register your user today.': 'Opprett din bruker i dag.',
    '30 days free usage for all new users. Afterwards you can choose if you would like to continue with Free or Premium subscription':
        '30 dager fri bruk for alle nye brukere. Deretter velger du  om du vil fortsette med gratis- eller premiumabonnement.',
    here: 'her',
    'Change pattern': 'Endre oppskrift',
    'Click here if you want to delete your account':
        'Klikk her hvis du vil slette kontoen din',
    'Delete account?': 'Slette konto?',
    'Deleting your account will delete all data and media related to your account':
        'Hvis du sletter kontoen din, slettes alle data og media relatert til kontoen din',
    'This action cannot be undone': 'Denne handlingen kan ikke angres',
    'We would really like to know why you want to delete your account. If you would like, tell us below':
        'Vi vil veldig gjerne vite hvorfor du ønsker å slette kontoen din. Hvis du vil, fortell oss nedenfor',
    Add: 'Legg til',
    'row counter': 'omgangsteller',
    'row counters': 'omgangstellere',
    Show: 'Vis',
    picture: 'bilde',
    'Row counter': 'Omgangsteller',
    Cancel: 'Avbryt',
    'I invite you to Knit&Note': 'Jeg inviterer deg til Knit&Note',
    'Hi! Check out this knitting app': 'Sjekk ut denne strikkeappen',
    'I use this to gather all of my knitting in one place.':
        'Her samler jeg alt på ett sted.',
    'Patterns, projects, photos, knitting tools and even yarn and needle stash.':
        'Mine strikkeprosjekter, bilder, oppskrifter, garn og pinnelager.',
    'Share invitation': 'Del invitasjon',
    'Yarn usage': 'Garnbruk',
    Refresh: 'Forfriske',
    'Loading new version. Please wait..':
        'Laster ny versjon. Vennligst vent ..',
    'is the latest version': 'er den nyeste versjonen',
    skeins: 'nøster',
    Skeins: 'Nøster',
    'Cannot copy link because of browser permissions. Adjust permissions, or use the link below to invite your friends.':
        'Kan ikke kopiere lenken på grunn av nettlesertillatelser. Juster tillatelser, eller bruk lenken nedenfor for å invitere vennene dine.',
    'Invite your friends to be a part of our community as well.':
        'Inviter vennene dine til å være en del av fellesskapet vårt også.',
    'Share by clicking on "{{link}}"': 'Del invitasjon ved å trykke "{{link}}"',
    'You can see how many who has registered through your link above.':
        'Du ser hvor mange som har benyttet din link ovenfor.',
    Terms: 'Vilkår',
    'How it works': 'Slik virker det',
    'Invite friends - get free premium': 'Inviter venner - få  gratis premium',
    'As you have been invited, you get 60 days free premium':
        'Som invitert får du 60 dagers fri bruk av premium',
    'Premium gives you access to': 'Premium gir deg tilgang til',
    'More than 2 pictures per project': 'Mer enn 2 bilder per prosjekt',
    'After {{days}} days you can choose to continue with basic or premium membership':
        'Etter {{days}} dager velger du om du vil fortsette med gratis eller premium abonnement',
    'I hope you get a lot of joy from knit&note':
        'Jeg håper du får masse glede av knit&note!',
    'Free premium will expire soon': 'Gratis premium utløper snart',
    'To keep your access to premium after the free period, you must upgrade your subscription.':
        'For å beholde tilgang til premium etter gratisperioden må du oppgradere abonnementet ditt.',
    'The premium features are': 'Premiumfunksjonene er',
    'When the free period is over, you can get a premium for 2.99 USD a month, or 29 USD for one year':
        'Når gratisperioden er over får du premium for 2,99 USD i måneden, eller 29 USD for ett år',
    'Hello, and welcome to': 'Hei, og velkommen til',
    'We are <strong>so</strong> happy to see you here':
        'Vi er <strong>så</strong> glade for å se deg her',
    'Create user': 'Opprett bruker',
    'The next payment is scheduled on {{date}}':
        'Den neste betalingen er planlagt {{date}}',
    'Terms for giveaway': 'Betingelser for giveaway',
    until: 'frem til',
    '{{colorCount}} colors': '{{colorCount}} farger',
    '1 color': '1 farge',
    'Do you want to return the yarn from this project back to stash':
        'Vil du returnere garnet fra dette prosjektet til lager',
    'Link copied to clipboard': 'Linken er kopiert til utklippstavlen',
    'Could not copy the link to clipboard':
        'Kunne ikke linken kopiere til utklippstavlen',
    'Remove Pattern': 'Fjern oppskrift',
    'Are you sure you want to remove the pattern':
        'Er du sikker på at du vil fjerne oppskriften',
    'from the project?': 'fra prosjektet?',
    'Any highlights will also be removed.': 'Eventuell markering fjernes også.',
    Account: 'Konto',
    Metric: 'Metrisk',
    Imperial: 'Imperial',
    'Change picture': 'Endre bilde',
    'Select from yarn color': 'Velg fra garnfarge',
    'Select which yarn color picture you would like to use':
        'Velg hvilket garnfargebilde du vil bruke',
    'Upload a new picture below': 'Last opp et nytt bilde nedenfor',
    'Add new picture': 'Legg til nytt bilde',
    'Thanks for sharing!': 'Takk for at du delte!',
    'Waiting for confirmation': 'Venter på bekreftelse',
    'Something went wrong': 'Noe gikk galt',
    'We could not get a confirmation from stripe about your subscription':
        'Vi kunne ikke få en bekreftelse fra stripe om abonnementet ditt',
    'Please try again later': 'Prøv igjen senere',
    'Something went wrong. Please wait about 2 minutes before logging in again':
        'Noe gikk galt. Vennligst vent ca. 2 minutter før du logger på igjen',
    Badges: 'Medaljer',
    Subcategories: 'Underkategorier',
    Subcategory: 'Underkategori',
    'New subcategory': 'Ny underkategori',
    File: 'Fil',
    'Choose category': 'Velg kategori',
    'Choose subcategory': 'Velg underkategori',
    None: 'Ingen',
    'Parent category': 'Hoved kategori',
    'No patterns in this list': 'Ingen oppskrifter i denne listen',
    'From Google Drive': 'Fra Google Disk',
    'Replace from Google Drive': 'Bytt fra Google Disk',
    'Replace from local': 'Bytt fra lokale',
    Home: 'Hjem',
    'Upload as images': 'Last opp bilder',
    'Pattern page preview': 'Forhåndsvisning av mønsterside',
    'Pattern pages': 'Oppskriftssider',
    'per skein': 'per nøste',
    'Change status': 'Endre status',
    'Choose project status': 'Velg prosjektstatus',
    Planned: 'Planlagt',
    'Because you have added a coupon, you get 90 days free premium':
        'Fordi du har lagt til en kupong, får du 90 dager gratis premium',
    'You can press, hold, and drag pages to change the order':
        'Du kan trykke, holde og dra sider for å endre rekkefølgen',
    Page: 'Side',
    'Preview all': 'Forhåndsvis alle',
    'Measurement system': 'Målesystem',
    'None selected - default is metric': 'Ingen valgt - standard er metrisk',
    'Check the boxes below if you would like us to convert the current measurement values to the respective values in the new system for the following:':
        'Kryss av i boksene nedenfor hvis du vil at vi skal konvertere gjeldende måleverdier til de respektive verdiene i det nye systemet for følgende:',
    Needles: 'Pinner',
    'Depending on the number of calculations, this might take a while':
        'Avhengig av antall beregninger, kan dette ta litt tid',
    Ounces: 'Unser',
    ounces: 'unser',
    'Measurements converted to metric': 'Målinger konvertert til metrisk',
    'Measurements converted to imperial': 'Målinger konvertert til imperial',
    'What is your preferred measurement system?':
        'Hva er ditt foretrukne målesystem?',
    'Uploading a pdf or images is required for adding a pattern':
        'Det kreves å laste opp en pdf eller bilder for å legge til et oppskrift',
    'All patterns in this category are also deleted.':
        'Alle oppskriftene i kategorien slettes også.',
    'will be returned to stash.': 'vil bli returnert til lager.',
    Return: 'Returner',
    'Would you like to return': 'Vil du returnere',
    'to the yarn stash?': 'til garnlageret?',
    'of yarn you have used this year': 'garn du har brukt i år',
    'skeins you have used this year': 'nøster du har brukt i år',
    'Search for patterns and tags': 'Søk etter oppskrifter og tagger',
    PATTERNS: 'OPPSKRIFTER',
    TAGS: 'TAGGER',
    'New pattern': 'Ny oppskrift',
    'New Category': 'Ny kategori',
    'New category': 'Ny kategori',
    'Something went wrong when loading the pdf':
        'Noe gikk galt under innlasting av pdf-en',
    'The pdf could not be found on the server':
        'PDF-filen ble ikke funnet på serveren',
    'Weight cannot exceed': 'Vekten kan ikke overstige',
    'Quantity cannot exceed': 'Antallet kan ikke overstige',
    'If you document your projects, getting this badge will be a snap.':
        'Dersom du dokumenterer prosjektene dine vil denne medaljen fort være innen rekkevidde.',
    'You have added {{level}} photos to your projects.':
        'Du har lagt til {{level}} bilder i prosjektene dine.',
    'Your projects are a work of art.':
        'Prosjektene dine er virkelig noen flotte kunstverk!',
    'Picture this: another badge is all yours.':
        'Se for deg dette: En ny medalje er din.',
    'Your knitting is picture perfect.':
        'Strikkingen din tar seg virkelig flott ut på bilder.',
    'How much yarn have you used lately? You’ll have to check to get this badge.':
        'Hvor mye garn har du egentlig brukt i det siste? Det får du finne ut om du ønsker å oppnå denne medaljen.',
    'You have checked your Yarn Usage {{level}} times.':
        'Du har sjekket garnstatistikken din {{level}} ganger.',
    'You are watching those yarn stats like a hawk.':
        'Du følger med som en hauk!',
    'You’ve been keeping your eyes on the prize.':
        'Du holder virkelig øynene festen på premien her!',
    'We’ve crunched the numbers, and your observation skills are top notch.':
        'Du har knust alle tall og dine observasjons egenskaper er virkelig på topp.',
    'You only have 7 days, from start to finish, to get this badge.':
        'Du har 7 dager på deg fra start til slutt på å oppnå denne medaljen.',
    'You have finished {{level}} projects in one week or less.':
        'Du har fullført {{level}} prosjekter på en uke eller mindre.',
    'Congrats! You crossed the finishing line.':
        'Gratulerer! Du har krysset målstreken.',
    'A project a day keeps the boredom away.':
        'Her har du ikke ligget på latsiden! Et prosjekt om dagen er virkelig imponerende.',
    'It looks like your work here is finished.':
        'Det ser ut til at din jobb her er fullført!',
    'If it’s this badge you want to obtain, you’ll have to make the same thing again and again.':
        'Hvis dette er en medalje du ønsker å ha i samlingen kan det lønne seg å lage samme plagg om og om igjen.',
    'You have knit the same pattern {{level}} times.':
        'Du har strikket etter samme oppskrift {{level}} ganger.',
    'Lather, rinse, repeat. Another project is done.':
        'Vask, skyll og gjenta! Enda et prosjekt er fullført.',
    'Repeat after me: “I got another badge!”':
        'Gjenta etter meg: Jeg har fått en ny medalje!',
    'If history has a way of repeating itself, then your projects are historic!':
        'Dersom historien har en måte å gjenta seg selv på, så er virkelig prosjektene dine historiske!!',
    'If you’re a pattern collector, getting this badge will be easy.':
        'Hvis du er en som liker å samle på oppskrifter vil det være enkelt for deg å få denne medaljen.',
    'You have {{level}} patterns in your library.':
        'Du har {{level}} oppskrifter i ditt bibliotek.',
    'Looks like you’re in a holding pattern, but in a good way.':
        'Ser ut til at du begynner å få en liten samling her nå!',
    'Your behaviour pattern is looking promising.':
        'Imponerende samling du har fått deg. Du liker virkelig å holde orden i biblioteket ditt!',
    'It is starting to look like the Library of Alexandria around here.':
        'Det begynner å ligne på Biblioteket i Alexandria her nå!',
    'Inviting others will be sure to get you this badge.':
        'Ønsker du å sikre deg denne medaljen kan det lønne seg å invitere flere venner til å bruke appen.',
    'You have invited {{level}} people to join the Knit&Note App.':
        'Du har invitert {{level}} venner til å bruke Knit&Note!',
    'We appreciate that you’re spreading the good word.':
        'Vi setter stor pris på at du er med å sprer det gode ordet!',
    'Wow, you’re drawing a crowd!':
        'Wow, du tiltrekker deg jo en hel folkemengde!',
    'You’re the life of the knitting party!':
        'Du må virkelig være sentrum på strikkefesten!',
    'If you want this badge, you’ll have to start pulling your (yarn) weight around here.':
        'Hvis dette er en medalje du ønsker å åpne må du virkelig begynne å dra (garn) vekten din rundt her!',
    'You have used {{level}} {{unit}} of yarn.':
        'Du har nå brukt {{level}} {{unit}} garn.',
    'With that much yarn used, the scales are really tipping in your favor.':
        'Med så mye brukt garn, begynner virkelig vekten å tippe over i din favør!',
    'The fact that you’ve been knitting a lot is a real weight off my mind.':
        'Du har virkelig fått strikket en del i det siste!',
    'The judges have weighed in, and it turns out that you knit a ton!':
        'Er det mulig? Når du har brukt så mye garn kan vi bare anta at du har tryllet med pinnene i det siste!',
    'It’s easy to get this badge, as long as you fit us into your daily routine.':
        'Den bør være en grei sak å oppnå, så lenge du har k&n med i din daglige rutine.',
    'You have used the Knit&Note App every day for {{level}} days.':
        'Du har brukt knit&note hver dag i {{level}} dager.',
    'You’re becoming a regular around here.':
        'Du begynner å bli en stamgjest hos oss.',
    'We’re glad you are enjoying your stay.':
        'Vi er så glade for å se at du like strikkeuniverset.',
    'It’s official, you’ve made us a habit. One that we hope you’ll keep.':
        'Det er offisielt, du har gjort oss til en vane. Håper det er en du vil beholde.',
    'Keep it up 👏🏻 Your overview so far this year:':
        'Fortsett sånn 👏🏻 Din oversikt så langt i år:',
    'See all': 'Se alle',
    'My stash': 'Mitt stæsj',
    'Video tutorials': 'Hjelpevideoer',
    'My badges': 'Mine medaljer',
    'Latest from the blog': 'Siste fra bloggen',
    'Good morning': 'God morgen',
    'Good afternoon': 'God ettermiddag',
    'Good evening': 'God kveld',
    'Complete your first project and enjoy the overview here:':
        'Fullfør ditt første prosjekt og gled deg over å få oversikten her:',
    used: 'brukt',
    'You have no active projects. Click the button on the bottom right to start one.':
        'Du har ingen aktive prosjekter. Klikk på knappen nede til høyre for å starte et.',
    Loading: 'Laster',
    Community: 'Fellesskap',
    More: 'Mer',
    Journal: 'Notater',
    Overview: 'Oversikt',
    'decrease calculator': 'felle kalkulator',
    'Increase/decrease calculator': 'Øke/felle kalkulator',
    'Yarn calculator': 'Garnkalkulator',
    'Try premium for 2,99 USD/month': 'Prøv premium for 29kr/mnd',
    'Includes yarn stash and needle stash':
        'Inkluderer garnlager og pinnelager',
    'Settings and help': 'Innstillinger og hjelp',
    'How to use the app': 'Hvordan bruke appen',
    'Account and settings': 'Konto og innstillinger',
    Website: 'Nettside',
    'Follow us on Instagram': 'Følg oss på Instagram',
    'We would love to hear from you if you have any questions or feedback. Contact:':
        'Vi vil gjerne høre fra deg om du har spørsmål eller tilbakemeldinger. Kontakt oss på:',
    '{{activeCount}} active projects': '{{activeCount}} aktive prosjekter',
    '1 active project': '1 aktivt prosjekt',
    '{{plannedCount}} planned projects':
        '{{plannedCount}} planlagte prosjekter',
    '1 planned project': '1 planlagt prosjekt',
    'You have completed {{completedCount}} projects this year':
        'Du har fullført {{completedCount}} prosjekter i år',
    "You have no active projects. Why don't you start a new one?":
        'Du har ingen aktive prosjekter. Start et nytt da vel.',
    "You have no planned projects. Why don't you plan a new one?":
        'Du har ingen planlagte prosjekter. Planlegg et nytt da vel.',
    "You have no completed projects this year, but don't let that stop you.":
        'Du har ingen fullførte prosjekter i år, men ikke la det stoppe deg.',
    'Name of the designer': 'Navn på designer',
    'Move to planned': 'Flytt til planer',
    Details: 'Detaljer',
    'You have not added yarn yet': 'Du har ikke lagt til garn enda',
    'Click the button to the right to add':
        'Klikk på knappen til høyre for å legge til',
    'You have not added details yet': 'Du har ikke lagt til detaljer enda',
    'Click the button to the right to edit':
        'Klikk på knappen til høyre for å redigere',
    'Add row counter': 'Legg til omgangsteller',
    Select: 'Velg',
    'E.g. "large"': 'F.eks. "large"',
    'E.g. "me"': 'F.eks. "meg"',
    'Latest posts': 'Siste innlegg',
    'Invite your friends to knit&note': 'Inviter vennene dine til knit&note',
    'Get 1 month premium for free': 'Få 1 måneds premium gratis',
    'My Badges': 'Mine medaljer',
    'Congratulations!': 'Gratulerer!',
    'You just got the {{levelTitle}} {{badgeName}} badge!':
        'Du har nettopp fått {{levelTitle}} {{badgeName}}-merket!',
    'You’ll have to go to great lengths (of yarn) to get this badge.':
        'Du vil måtte gå store distanser (i garn) for å oppnå denne medaljen.',
    'You have used {{level}} metres of yarn.':
        'Du har brukt {{level}} meter med garn.',
    'You did it! You completed a half-marathon.':
        'Du klarte det! Du har nettopp fullført et halvmaraton!',
    'Congrats! You finished a full marathon.':
        'Gratulerer!! Du har fullført et maraton!',
    'That’s almost as wide as the English Channel!':
        'Det er nesten like langt som over den engelske kanal!',
    Share: 'Del',
    'Want to share in the feed?': 'Vil du dele i feeden?',
    'Select photos (max. 5)': 'Velg bilder (maks. 5)',
    'Write caption': 'Skriv bildetekst',
    Caption: 'Bildetekst',
    'Details shared': 'Detaljer som deles',
    'Project name and status': 'Prosjektnavn og status',
    'Pattern name and designer': 'Oppskriftsnavn og designer',
    'Size, gauge and needle size':
        'Størrelse, strikkefasthet og pinnestørrelse',
    'Share project': 'Del prosjektet',
    posts: 'innlegg',
    Posts: 'Innlegg',
    post: 'innlegg',
    Post: 'Innlegg',
    likes: 'likerklikk',
    Likes: 'Likerklikk',
    // 'everyday pulse': 'hverdagspuls',
    'Add comment': 'Legg til kommentar',
    'minutes ago': 'minutter siden',
    'hours ago': 'timer siden',
    'days ago': 'dager siden',
    Publish: 'Publiser',
    // 'Show comments': 'Vis kommentarene',
    'Show all {{number}} comments': 'Vis alle {{number}} kommentarene',
    // 'Skjul kommentarer': 'Skjul kommentarer',
    'Like by <strong>{{name}}</strong> and <strong>{{count}} others</strong>':
        'Likt av <strong>{{name}}</strong> og <strong>{{count}} andre</strong>',
    like: 'likerklikk',
    'has started following you': 'har begynt å følge deg',
    'has asked to follow you': 'har bedt om å få følge deg',
    Decline: 'Avslå',
    Confirm: 'Bekreft',
    'likes your post': 'liker innlegget ditt',
    'has commented on your post': 'har kommentert innlegget ditt',
    'replied to your comment': 'svarte på kommentaren din',
    'Do you want to cancel?': 'Vil du avbryte?',
    'If you cancel sharing of project, your post will be discarded':
        'Dersom du avbryter deling av prosjektet vil innlegget ditt forkastes',
    'Not now': 'Ikke nå',
    'Upload picture': 'Last opp bilde',
    'How exciting that you want to inspire others by sharing your projects':
        'Så gøy at du ønsker å inspirere andre ved å dele dine prosjekter',
    'Create a profile': 'Opprett profil',
    'So glad you are adding photos to your project':
        'Så gøy at du legger til bilder i prosjektet ditt',
    'Why not share the update in the feed with your knitting friends?':
        'Hvorfor ikke dele oppdateringen i feeden med dine strikkevenner?',
    'Private account': 'Kontoen er privat',
    'Ask to follow this account to see their projects':
        'Be om å følge denne kontoen for å se prosjektene deres',
    'Ask to follow': 'Be om å få følge',
    'Request sent': 'Forespørsel sendt',
    Requested: 'Forespurt',
    'Edit profile': 'Rediger profil',
    Saved: 'Lagret',
    'has not posted any projects yet': 'har ikke lagt ut noen prosjekter enda',
    'does not have any medals yet': 'har ikke noen medaljer enda',
    'see more': 'se mer',
    Active: 'Aktivt',
    'Search for patterns and tags in': 'Søk etter oppskrifter og tagger i',
    'Your knitting community will be launched this fall - soon you can share your projects with others':
        'Ditt strikkefellesskap lanseres i høst – snart kan du dele prosjektene dine med andre',
    'Skeins cannot be edited on completed projects. If you need to do it, first move the project to active status.':
        'Nøster kan ikke redigeres i fullførte prosjekter. Hvis du trenger å gjøre det, må du først flytte prosjektet til aktiv status.',
    'Hold, drag and drop the planned projects in the order you want':
        'Hold, dra og slipp de planlagte prosjektene i den rekkefølgen du ønsker',
    'Swipe to the left to quickly bring up the project pattern':
        'Swipe mot venstre for å kjapt få opp oppskriften til prosjektet',
    'Search for brand, type, gauge, quality, color':
        'Søk merke, type, strikkefasthet, kvalitet, farge',
    'First name': 'Fornavn',
    'Last name': 'Etternavn',
    Update: 'Oppdater',
    'Update profile': 'Oppdater profil',
    'Enter your email address and we will send you a link to reset your password':
        'Skriv inn e-postadressen din, så sender vi deg en lenke for å tilbakestille passordet ditt',
    'First Name': 'Fornavn',
    'Last Name': 'Etternavn',
    'Already have a user?': 'Har du allerede en bruker?',
    'User has been created!': 'Brukeren er opprettet!',
    'We only need a bit more information before you can get started :)':
        'Vi trenger kun et par opplysninger før du kan komme i gang :)',
    Preferences: 'Preferanser',
    'Choose the country you live in': 'Velg hvilket land du bor i',
    'Choose language': 'Velg språk',
    'Choose measurement system': 'Velg målesystem',
    'We are so happy to see you here!': 'Vi er så glade for å se deg her!',
    'You are now together with knitters from all over the world, who love to create, inspire and be inspired.':
        'Du er nå sammen med strikkere fra hele verden, som elsker å skape, inspirere og bli inspirert.',
    'Get the overview, be inspired and share the joy of creation':
        'Få oversikt, bli inspirert og del skapergleden',
    'Create new user': 'Opprett ny bruker',
    'Welcome back': 'Velkommen tilbake',
    'Welcome to the Community': 'Velkommen til strikkefellesskapet',
    'We hope that YOU want to inspire and be inspired by sharing projects with your knitting friends :)':
        'Vi håper DU vil være med å inspirere og bli inspirert ved å dele prosjekter med strikkevennene dine :)',
    'You choose which projects you want to share. Create a profile now and get inspired!':
        'Du velger selv hvilke prosjekter du ønsker å dele. Opprett profil nå og bli inspirert!',
    'Create profile': 'Opprett profil',
    'Add username': 'Legg til brukernavn',
    'Name and username will be visible on your profile and will be searchable when others are looking for your profile.':
        'Navn og brukernavn vil være synlig på profilen din og vil være søkbart når noen leter etter din profil.',
    'Private profile': 'Privat profil',
    Off: 'Av',
    On: 'På',
    'If you turn on “private profile”, your projects will only be visible to users you have approved.':
        'Dersom du skrur på “privat profil” vil prosjektene dine kun være synlig for brukere du har godkjent.',
    'We hope you keep your profile open to easily inspire and connect with other knitters :)':
        'Vi håper du holder profilen din åpen for å lettere inspirere og knytte kontakt med andre strikkere :)',
    'Add a short bio': 'Legg til en kort bio',
    'Bio will be visible on your profile and can be changed.':
        'Bio vil være synlig på profilen din og kan endres.',
    Skip: 'Hopp over',
    'Add profile picture': 'Legg til profilbilde',
    'Add at least 3 interests': 'Legg til minst 3 interesser',
    'We use this information to customize content for you. Your interests will be visible on your profile and can be changed.':
        'Vi bruker denne informasjonen for å tilpasse innhold til deg. Interessene vil være synlig på profilen din og kan endres.',
    'Welcome to the feed!': 'Velkommen til feeden!',
    'Here you will see posts shared by knitting friends that you follow :)':
        'Her vil du se innlegg delt av strikkevenner som du følger :)',
    'Find users': 'Finn brukere',
    'Or search and explore yourself by pressing the search icon in the top menu.':
        'Eller søk og utforsk selv ved å trykke på søkeikonet i toppmenyen.',
    'How exciting that you want to inspire others by sharing your project!':
        'Så gøy at du ønsker å inspirere andre ved å dele dine prosjekter!',
    'But before you can share, you must first create a profile in Community :)':
        'Men før du kan dele må du først opprette en profil i Fellesskap :)',
    // ' on a successful project!': ' med vel gjennomført prosjekt!',
    'Well done :)': 'Godt jobba :)',
    'So glad you are adding photos to your project!':
        'Så gøy at du legger til bilder i prosjektet ditt!',
    'Why not share the update in the feed with your knitting friends? :)':
        'Hvorfor ikke dele oppdateringen i feeden med dine strikkevenner? :)',
    // 'But before you can share, you must first upload one or more images to the project :)':
    //     'Men før du kan dele må du først laste opp ett eller flere bilder til prosjektet :)',
    'To edit yarn, you must first move the project to active projects.':
        'For å redigere garn må du først flytte prosjektet til aktive prosjekter.',
    'To add yarn, you must first move the project to active projects.':
        'For å legge til garn må du først flytte prosjektet til aktive prosjekter.',
    'Move to active projects': 'Flytt til aktive prosjekt',
    Username: 'Brukernavn',
    username: 'brukernavn',
    Interests: 'Interesser',
    'Changes, tips or useful information':
        'Endringer, tips eller nyttig informasjon',
    'Do you have any comments you can add?':
        'Har du noen kommentarer du kan legge til?',
    'Comments on the project': 'Kommentarer til prosjektet',
    'You have seen all posts': 'Du har sett alle innlegg',
    'To the top': 'Til toppen',
    'Select the project you want to share': 'Velg prosjektet du vil dele',
    'Before you can share': 'Før du kan dele',
    'In order for it to be of help and inspiration to others, you must first add':
        'For at det skal være til hjelp og inspirasjon for andre, må du først legge til',
    'Got it': 'Den er grei',
    'Go to project': 'Gå til prosjekt',
    'You have no active projects.': 'Du har ingen aktive prosjekter.',
    'You have no completed projects.': 'Du har ingen fullførte prosjekter.',
    'You have no planned projects.': 'Du har ingen planlagte prosjekter.',
    'weeks ago': 'uker siden',
    'week ago': 'uke siden',
    'day ago': 'dag siden',
    'hour ago': 'time siden',
    'seconds ago': 'sekunder siden',
    'minute ago': 'minutt siden',
    projects: 'prosjekter',
    followers: 'følgere',
    following: 'følger',
    'None of the knitters you follow have shared any project yet.':
        'Ingen av strikkerne du følger har delt noen prosjekter ennå.',
    'Why not explore to see if you find new knitters to follow?':
        'Hvorfor ikke utforske for å se om du finner nye strikkere å følge?',
    'Suggested users': 'Foreslåtte brukere',
    'Share your first projects so others can be inspired by you':
        'Del dine første prosjekter slik at andre kan bli inspirert av deg',
    'No activity yet': 'Ingen aktivitet enda',
    'Delete post': 'Slett innlegg',
    'Are you sure you want to delete the post?':
        'Er du sikker på at du vil slette innlegget?',
    'Profile not found': 'Finner ikke profil',
    'We could not find this profile.': 'Vi kunne ikke finne denne profilen.',
    Explore: 'Utforsk',
    'Search for profiles, project details':
        'Søk etter profiler, prosjektdetaljer',
    Profiles: 'Profiler',
    Follow: 'Følg',
    Following: 'Følger',
    Follows: 'Følger',
    follows: 'følger',
    'Share in feed': 'Del i feed',
    'Project name, pattern name and designer':
        'Prosjektnavn, oppskriftsnavn og designer',
    Project: 'Prosjekt',
    Search: 'Søk',
    Followers: 'Følgere',
    Activity: 'Aktivitet',
    follower: 'følger',
    'How exciting that you’re adding photos to your project!':
        'Så gøy at du legger til bilder i prosjektet ditt!',
    photo: 'bilde',
    size: 'størrelse',
    'needle size': 'pinnestørrelse',
    gauge: 'strikkefasthet',
    'has been rejected from following you': 'har blitt avvist fra å følge deg',
    'Load more': 'Last mer',
    Reply: 'Svar',
    'likes your comment': 'liker kommentaren din',
    'Hide comments': 'Skjul kommentarer',
    Other: 'Annet',
    'Add designer name': 'Legg til designernavn',
    'Add pattern name': 'Legg til oppskriftnavn',
    'Pattern name': 'Oppskriftnavn',
    'Designer name': 'Designernavn',
    'Remove followers': 'Fjern følger',
    Remove: 'Fjern',
    'Are you sure you want to remove': 'Er du sikker på at du vil',
    'from your followers?': 'fra følgerne dine?',
    'Are you sure you want to delete the pattern':
        'Er du sikker på at du vil slette oppskriften',
    'Are you sure you want to delete the project':
        'Er du sikker på at du vil slette prosjektet',
    'Delete project': 'Slett prosjekt',
    'Are you sure you want to delete the yarn color':
        'Er du sikker på at du vil slette garnfargen',
    'Are you sure you want to delete needle size':
        'Er du sikker på at du vil slette pinnestørrelse',
    'the category': 'kategorien',
    'Delete yarn color': 'Slett garnfarge',
    'Delete needle size': 'Slett pinnestørrelse',
    'Suggested for you': 'Foreslått for deg',
    'Followed by {{follower_count}}': 'Følges av {{follower_count}}',
    'has accepted the invitation': 'har takket ja til invitasjonen',
    Maintenance: 'Vedlikehold',
    'Due to an amazing growth of users in the app, we need to make some necessary updates to our server.':
        'På grunn av en fantastisk vekst av brukere i appen må vi gjøre noen nødvendige oppdateringer på serveren vår.',
    'This may take a while and we apologize for the inconveniences this causes <3':
        'Dette kan ta noe tid og vi beklager ulempene dette medfører <3',
    'You may try the button below to test if the update is complete. If not, please try again in a while :)':
        'Du kan prøve knappen nedenfor for å teste om oppdateringen er ferdig. Hvis ikke, prøv igjen om en stund :)',
    Reload: 'Last på nytt',
    'Are you sure you want to delete this relation':
        'Er du sikker på at du vil slette denne relasjonen',
    'Deleting Relation': 'Sletter relasjon',
    'Please add a reason to report this.':
        'Legg til en grunn for å rapportere dette.',
    Report: 'Rapporter',
    'Why do you want to report this?': 'Hvorfor vil du rapportere dette?',
    'My subscription': 'Mitt abonnement',
    Free: 'Gratis',
    'Access to everything – with ads': 'Tilgang til alt – med reklame',
    'Do you want to change subscription?': 'Vil du bytte abonnement?',
    Year: 'År',
    Month: 'Måned',
    year: 'år',
    month: 'mnd',
    'Access to everything – Ad-free': 'Tilgang til alt – Reklamefri opplevelse',
    'You pay once a year': 'Du betaler en gang i året',
    'Cancel at any time': 'Ingen bindingstid',
    'You pay once a month': 'Du betaler en gang i måneden',
    'Change subscription': 'Endre abonnement',
    'Premium trial': 'Premium prøveversjon',
    'Premium for free': 'Premium gratis',
    'Premium Library for free': 'Premium bibliotek gratis',
    'No followers': 'Ingen følgere',
    'Select date': 'Velg dato',
    'Starting date': 'Startdato',
    'Completion date': 'Fullføringsdato',
    'Sharing aborted': 'Deling avbrutt',
    'Send reset email': 'Send tilbakestillings-e-post',
    'Enter your code here': 'Tast inn koden din her',
    'Enter your code below and start testing':
        'Tast inn koden din nedenfor, og kom i gang med testingen',
    'Welcome to the beta testing of Knit&note!':
        'Velkommen til betatesting av Knit&note!',
    'Access not granted right now': 'Tilgang ikke gitt akkurat nå',
    Dashboard: 'Dashboard',
    'Go back': 'Gå tilbake',
    Type: 'Type',
    'No video found': 'Ingen video funnet',
    Supporter: 'Bidragsyter',
    Premium: 'Premium',
    Basic: 'Basic',
    'Includes everything from premium': 'Inkluderer alt fra premium',
    'You support a little extra the development of the app':
        'Du støtter litt ekstra i utviklingen av appen',
    'Exclusive offers every month': 'Eksklusive tilbud hver måned',
    'Something went wrong with Stripe. Please try again later.':
        'Noe gikk galt med Stripe. Prøv igjen senere.',
    'Upload and save your patterns': 'Last opp og lagre mønstrene dine',
    'Create custom categories': 'Lag egendefinerte kategorier',
    'Add personal tags': 'Legg til personlige tagger',
    'Create projects and add 2 pictures': 'Lag prosjekter og legg til 2 bilder',
    'Calendar Notes': 'Kalendernotater',
    Medals: 'Medaljer',
    'Want to share in feed?': 'Vil du dele i feed?',
    'No Follower Found': 'Ingen følger funnet',
    'This username is already taken': 'Dette brukernavnet er allerede tatt',
    'No patterns found': 'Ingen oppskrifter funnet',
    'No project found': 'Ingen prosjekt funnet',
    Close: 'Lukk',
    'Upgrade to premium for access': 'Oppgrader til premium for tilgang',
    'Yarn from stash': 'Garn fra lager',
    'This yarn will not be connected to the yarn stash. If you wish to deduct it from your yarn stash go back and choose "Get from yarn stash"':
        'Dette garnet vil ikke kobles til garnlageret. Hvis du ønsker å trekke det fra garnlageret ditt, gå tilbake og velg "Få fra garnlager"',
    'Weight per skein': 'Vekt per nøste',
    'Length per skein': 'Løpelengde',
    'Add counter': 'Legg til teller',
    'View counter': 'Vis teller',
    'View pattern': 'Se mønster',
    'Set pattern': 'Sett mønster',
    Images: 'Bilder',
    'No blog found': 'Finner ingen blogg',
    yards: 'yards',
    'Register user': 'Registrer bruker',
    'Repeat Password': 'Gjenta passord',
    'Set email and choose password, then you can get started':
        'Sett e-post og velg passord, så kan du komme i gang',
    Hi: 'Hei',
    'Invalid Google response.': 'Ugyldig Google-svar.',
    'Sign in with Facebook': 'Logg inn med Facebook',
    'Invalid Facebook response.': 'Ugyldig Facebook-svar.',
    'You are already using the imperial measurement system':
        'Du bruker allerede det imperiske målesystemet',
    'You are already using the metric measurement system':
        'Du bruker allerede det metriske målesystemet',
    'Re-calculating measurements': 'Omberegning av mål',
    'Choose password': 'Velg passord',
    Hashtags: 'Hashtags',
    'No hashtags found': 'Ingen hashtags funnet',
    'Access to everything - Ad free': 'Tilgang til alt – Reklamefri opplevelse',
    'Other subscriptions': 'Andre abonnementer',
    'tagged you in a post': 'tagget deg i et innlegg',
    'tagged you in a comment': 'tagget deg i en kommentar',
    'Decrease/ increase calculator': 'Felle/ øke kalkulator',
    Decrease: 'Felle',
    Increase: 'Øke',
    'No. of stitches on needle': 'Antall masker på pinnen',
    'No. of stitches to decrease': 'Antall fellinger',
    'No. of stitches to increase': 'Antall økninger',
    'No. of stitches': 'Antall masker',
    Calculate: 'Beregn',

    // NB! Calculater builds strings from parts, so deleting here won't give a compile error.
    //Decrease calculator
    'Knit <span>{{frequency}}</span> stitches':
        'Strikk <span>{{frequency}}</span> masker',
    'Knit <span>{{frequency}}</span> stitches, knit <span>2</span> together':
        'Strikk <span>{{frequency}}</span> masker, strikk <span>2</span> sammen',
    '*Knit <span>1</span> stitch, knit <span>2</span> together* <span>{{times}}</span> times':
        '*Strikk <span>1</span> maske, strikk <span>2</span> sammen* <span>{{times}}</span> ganger',
    '*Knit <span>{{frequency}}</span> stitches, knit <span>2</span> together* <span>{{times}}</span> times':
        '*Strikk <span>{{frequency}}</span> masker, strikk <span>2</span> sammen* <span>{{times}}</span> ganger',
    'Knit <span>2</span> together* <span>{{times}}</span> times':
        'Strikk <span>2</span> sammen* <span>{{times}}</span> ganger',
    'Knit <span>2</span> together': 'Strikk <span>2</span> sammen',
    '*Knit <span>2</span> together* <span>{{times}}</span> times':
        '*Strikk <span>2</span> sammen* <span>{{times}}</span> ganger',
    'Knit <span>1</span> stitch, knit <span>2</span> together':
        'Strikk <span>1</span> masker, strikk <span>2</span> sammen',
    //Increase calculator
    'Knit <span>1</span> stitch, increase <span>1</span> stitch':
        'Strikk <span>{{frequency}}</span> maske, øk <span>1</span> maske',
    'Knit <span>{{frequency}}</span> stitches, increase <span>1</span> stitch':
        'Strikk <span>{{frequency}}</span> masker, øk <span>1</span> maske',
    '*Knit <span>{{frequency}}</span> stitches, increase <span>1</span> stitch* <span>{{times}}</span> times':
        '*Strikk <span>{{frequency}}</span> masker, øk <span>1</span> maske* <span>{{times}}</span> ganger',
    '*Knit <span>1</span> stitch, increase <span>1</span> stitch* <span>{{times}}</span> times':
        '*Strikk <span>{{frequency}}</span> maske, øk <span>1</span> maske* <span>{{times}}</span> ganger',
    '*Knit increase <span>1</span> stitch* <span>{{times}}</span> times':
        '*Strikk øk <span>1</span> maske* <span>{{times}}</span> ganger',
    'Knit <span>1</span> stitch': 'Strikk <span>1</span> maske',
    'Increase <span>1</span> stitch': 'Øk <span>1</span> maske',
    'Cannot increase with more than {{count}} when you have {{count}} stitches on the needle':
        'Kan ikke øke med mer enn {{count}} når du har {{count}} masker på pinnen',
    'Cannot decrease with more than {{decreaseCount}} when you have {{count}} stitches on the needle':
        'Kan ikke felle med mer enn {{decreaseCount}} når du har {{count}} masker på pinnen',

    // 'Sorry, we can not calculate': 'Beklager, vi kan ikke beregne',
    // 'There has been too many requests. Please wait a minute before continuing':
    //     'Det har vært for mange forespørsler. Vent et minutt før du fortsetter',
    'Unfortunately, right now it is not possible to change your subscription in this app. For now this is only possible on our official website.':
        'Dessverre er det ikke mulig å endre abonnementet ditt i denne appe akkurat nå. Foreløpig er dette kun mulig på vår offisielle nettside.',
    // 'Supporter - Month': 'Bidragsyter - Måned',
    // 'Supporter - Year': 'Bidragsyter - År',
    // 'Premium - Month': 'Premium - Måned',
    // 'Premium - Year': 'Premium - År',
    'Terms & Conditions': 'Betingelser og vilkår',
    'My account': 'Min konto',
    Settings: 'Innstillinger',
    'About the app': 'Om appen',
    'Sign out': 'Logg ut',
    'Account & settings': 'Konto & innstillinger',
    Version: 'Versjon',
    'Newest version': 'Nyeste versjon',
    'If you delete your account, all projects, photos and information associated with your account will be deleted.':
        'Hvis du sletter kontoen din, slettes alle prosjekter, bilder og informasjon knyttet til kontoen din.',
    'This action cannot be undone.': 'Handlingen kan ikke angres.',
    'If you would, please tell us why:':
        'Hvis du vil, fortell oss gjerne hvorfor:',
    'Confirm deletion of account': 'Bekreft sletting av konto',
    'Are you sure you want to delete your account?':
        'Er du sikker på at du vil slette kontoen din?',
    'For those who love Knit&Note.': 'For deg som elsker Knit&Note.',
    Purchase: 'Kjøp',
    'Cannot purchase without first selecting one of the subscriptions above.':
        'Kan ikke kjøpe uten først å velge ett av abonnementene ovenfor.',
    'Much appreciated': 'Høyt verdsatt',
    'At least 8 characters are required for the password':
        'Passordet må minst ha 8 tegn',
    'Tips!': 'Tips!',
    'Click on the light bulb to keep the screen on while you view the pattern':
        'Klikk på lyspæren for å holde skjermen på mens du ser på oppskriften',
    Thickness: 'Tykkelse',
    'Add color': 'Legg til farge',
    'Previously logged in using Facebook or Google?':
        'Tidligere logget på med Facebook eller Google?',
    'Complete the following steps to login to your account:':
        'Fullfør følgende trinn for å logge på kontoen din:',
    'Select "forgot password"': 'Velg "glemt passord"',
    'Use the email address from your Facebook/ Google account and select "Send"':
        'Bruk e-postadressen fra Facebook/Google-kontoen din og velg "Send"',
    'You will receive an email with a link to set your new password.':
        'Du vil motta en e-post med en lenke for å angi ditt nye passord.',
    'Log in with the email address and new password.':
        'Logg inn med e-postadressen og nytt passord.',
    'Start over': 'Begynne på nytt',
    'Subscription Info': 'Info om abonnement',
    'You already have the subscription that you trying to purchase.':
        'Du har allerede det abonnementet du prøver å kjøpe.',
    'See your current subscription here': 'Se ditt nåværende abonnement her',
    'Please log in': 'Vennligst Logg inn',
    'Before you can purchase a subscription you need to log in or create an account':
        'Før du kan kjøpe et abonnement må du logge inn eller opprette en konto',
    // 'We have created this app to give knitters a way to get an overview, be inspired and share the joy of creation':
    //     'Vi har laget denne appen for å gi strikkere en måte å få oversikt, bli inspirert og dele skapergleden',
    // 'To continue, use the button below to create an account':
    //     'For å fortsette, bruk knappen nedenfor for å opprette en konto',
    Downgrade: 'Nedgrader',
    'Successfully updated your subscription': 'Abonnementet ditt er oppdatert',
    'Something went wrong with Stripe, Please try again later.':
        'Noe gikk galt med Stripe. Prøv igjen senere.',
    'Please wait while we are processing': 'Vennligst vent mens vi behandler',
    'We are not able to process. Please try again later.':
        'Vi kan ikke behandle forespørselen. Prøv igjen senere.',
    Desc: 'Beskrivelse',
    Qty: 'Antall',
    Sum: 'Sum',
    'Subscription Details': 'Abonnementsdetaljer',
    'Account & Settings': 'Konto & Innstillinger',
    'Supporter (Annual)': 'Bidragsyter (årlig)',
    'Supporter (Monthly)': 'Bidragsyter (månedlig)',
    'Contributor (Annual)': 'Bidragsyter (årlig)',
    'Contributor (Monthly)': 'Bidragsyter (månedlig)',
    'Next payment: {{date}}': 'Neste betaling: {{date}}',
    'Cancels on: {{date}}': 'Kansellerer på: {{date}}',
    Done: 'Ferdig',
    Total: 'Sum',
    Bio: 'Bio',
    Balance: 'Balanse',
    'Payable now': 'Betales nå',
    "When you upgrade a subscription, you'll only pay the price difference for the remaining time before next due date, and then the new price will be charged. For downgrades, the new price will be charged on the next due date.":
        'Når du oppgraderer et abonnement betaler du bare prisforskjellen for gjenværende tid frem til neste forfallsdato, og deretter vil den nye prisen bli trukket. For nedgraderinger vil den nye prisen bli trukket på neste forfallsdato.',
    gr: 'gr',
    oz: 'oz',
    'in stash': 'på lager',
    'Add new color': 'Legg til ny farge',
    'Are you sure you want to change your username from':
        'Er du sikker på at du vil endre brukernavnet ditt fra',
    to: 'til',
    // 'Are you sure you want to change the email from <span>{{previousEmail}}</span> to <span>{{newEmail}}</span>?':
    //     'Er du sikker på at du vil endre e-posten fra <span>{{previousEmail}}</span> til <span>{{newEmail}}</span>?',
    // 'Are you sure you want to change your username from <span>{{previousUserName}}</span> to <span>{{newUserName}}</span>?':
    //     'Er du sikker på at du vil endre brukernavnet ditt fra <span>{{previousUserName}}</span> til <span>{{newUserName}}</span>?',
    // 'Are you sure you want to change the email from <strong>{{previousEmail}}</strong> to <strong>{{newEmail}}</strong>?':
    //     'Er du sikker på at du vil endre e-posten fra <strong>{{previousEmail}}</strong> til <strong>{{newEmail}}</strong>?',
    // 'Are you sure you want to change your username from <strong>{{previousUserName}}</strong> to <strong>{{newUserName}}</strong>?':
    //     'Er du sikker på at du vil endre brukernavnet ditt fra <strong>{{previousUserName}}</strong> til <strong>{{newUserName}}</strong>?',
    Tools: 'Verktøy',
    'Calculate how many skeins you need of a yarn with different length than the yarn in the pattern.':
        'Beregn hvor mange nøster du trenger av et garn med ulik løpelengde enn garnet i oppskriften.',
    'Gauge must be the same.': 'Strikkefastheten må være den samme.',
    'Amount of skeins': 'Antall nøster',
    Yards: 'Yards',
    Meter: 'Meter',
    Amount: 'Antall',
    'Your yarn': 'Ditt garn',
    'You need {{skeins}} skeins with a length of {{weightUnit}}':
        'Du trenger {{skeins}} nøster med løpelengde {{weightUnit}}',
    'In pattern': 'I oppskriften',
    'Are you sure you want to change the email from':
        'Er du sikker på at du vil endre e-posten fra',
    Ruler: 'Linjal',
    "Don't forget the gauge!": 'Husk strikkefastheten!',
    'Got it!': 'Den er grei!',
    // 'You may use Knit&Note with the free version, or upgrade to premium/supporter for an ad-free experience and exclusive offers from our partners.':
    //     'Du kan bruke Knit&Note i gratisversjon, eller oppgradere til premium/bidragsyter for en reklamefri opplevelse og eksklusive tilbud fra våre partnere.',
    // 'Continue for free or upgrade from $2.5/month and get:':
    //     'Fortsett helt gratis eller oppgrader fra 24,9 kr/mnd og få:',
    // '<span>Ad-free</span> app': '<span>Reklamefri</span> app',
    // 'Exclusive <span>discounts</span> on yarn, patterns and accessories each month':
    //     'Eksklusive <span>rabatter</span> på garn, oppskrifter og tilbehør hver måned',
    // 'Check out the welcome email or knitandnote.com for more information about the different subscriptions!':
    //     'Sjekk velkomstmailen eller knitandnote.com for mer informasjon om de ulike abonnementene!',
    // 'Continue with free version': 'Fortsett med gratisversjon',
    'We recommend you to download the app:':
        'Vi anbefaler deg å laste ned appen:',
    'Or continue in browser': 'Eller fortsett i browser',
    'Your account was created using {{socialAccount}} login. Changing the email will disconnect your account from {{socialAccount}} login.':
        'Kontoen din er opprettet med {{socialAccount}} innlogging. Hvis du endrer e-post vil kontoen kobles fra {{socialAccount}} innlogging. ',
    'Change username': 'Endre brukernavn',
    'Change email': 'Endre e-post',
    'You now have {{stitchesCount}} stitches on the needle':
        'Du har nå {{stitchesCount}} masker på pinnen',
    'Remove yarn': 'Fjern garn',
    'Do you want to continue?': 'Vil du fortsette?',
    of: 'av',
    CM: 'CM',
    IN: 'IN',
    'Cannot increase': 'Kan ikke øke',
    'Cannot decrease': 'Kan ikke felle',
    'Are you sure you want to remove the yarn from the project':
        'Er du sikker på at du vil fjerne garnet fra prosjektet',
    'Are you sure you want to remove the yarn':
        'Er du sikker på at du vil fjerne garnet',
    'from the project': 'fra prosjektet',
    'Are you sure you want to delete the yarn':
        'Er du sikker på at du vil slette garnet',
    // 'Less than $2.5/month - paid once a year':
    //     'Under 25 kr/mnd - betales en gang i året',
    // 'Paid once a year': 'Betales en gang i året',
    'Ad-free app': 'Ingen reklame',
    'Exclusive discounts each month': 'Eksklusive rabatter hver måned',
    'You support us in the development of the app':
        'Du støtter oss i utviklingen av appen',
    // 'Paid once a month': 'Betales en gang i måneden',
    // 'My library pattern': 'Mitt biblioteksmønster',
    Trending: 'Trender',
    'Trending patterns': 'Oppskrifter som trender nå',
    'Report post': 'Rapporter innlegg',
    'Read more about pattern': 'Les mer om oppskriften',
    // 'Start project with pattern': 'Start prosjektet med mønster',
    // 'Plan project with pattern': 'Planlegg prosjekt med mønster',
    'Just added': 'Nylig lagt til',
    Designers: 'Designere',
    'Popular categories': 'Populære kategorier',
    'All categories': 'Alle kategorier',
    Library: 'Bibliotek',
    'Who are you knitting for?': 'Hvem strikker du til?',
    'Suitable for': 'Egnet for',
    Collections: 'Samling',
    'Search for pattern': 'Søk etter oppskrift',
    'Are you sure you want to delete the journal':
        'Er du sikker på at du vil slette notatet',
    'A small heads up!': 'En liten beskjed!',
    "This is still an experimental feature. If you find anything you think isn't right, please reach out to us so we can fix it :)":
        'Denne funksjonen eksperimenteres fortsatt med. Hvis du finner noe du mener ikke stemmer, ta gjerne kontakt med oss slik at vi kan fikse det :)',
    'Unfortunately, we could not calculate decreases for this scenario.':
        'Vi kunne dessverre ikke beregne fellinger for dette scenariet.',
    'Unfortunately, we could not calculate increases for this scenario.':
        'Vi kunne dessverre ikke beregne økninger for dette scenariet.',
    'Feel free to contact us so we can try to solve this.':
        'Ta gjerne kontakt med oss så vi kan prøve å løse dette.',
    'Uploaded File is corrrupted. Please choose another file':
        'Den opplastede filen er ødelagt. Velg en annen fil',
    'Updating to latest version! 🥳': 'Oppdaterer til siste versjon! 🥳',
    'You have not added any yarn. Get your stash up to date here.':
        'Du har ikke lagt til garn. Oppdater lageret ditt her.',
    'Invite your friends to be a part of our community 🤗':
        'Inviter vennene dine til å være en del av fellesskapet vårt 🤗',
    'Copied!': 'Kopiert!',
    'Link was copied to clipboard.': 'Linken ble kopiert til utklippstavlen.',
    'of your friends have signed up': 'av vennene dine har registrert seg',
    'Could not copy link': 'Kunne ikke kopiere lenken',
    'Here is your link:': 'Her er lenken din:',
    'Welcome!': 'Velkommen!',
    'We are so happy to see you here 🤗': 'Vi er så glade for å se deg her 🤗',
    'This app is for knitters and crocheters from all over the world who love to create, inspire and be inspired.':
        'Denne appen er for strikkere og heklere fra hele verden som elsker å skape, inspirere og bli inspirert.',
    'Start new project': 'Start nytt prosjekt',
    'Plan new project': 'Planlegg nytt prosjekt',
    'Remove pattern': 'Fjern oppskrift',
    'Duplicate project': 'Dupliser prosjekt',
    Image: 'Bilde',
    'Set as main image': 'Sett som hovedbilde',
    'Delete image': 'Slett bilde',
    Unfollow: 'Slutt å følge',
    'Why are you reporting the post?': 'Hvorfor rapporterer du innlegget?',
    'Why are you reporting the comment?': 'Hvorfor rapporterer du kommentaren?',
    'New update!': 'Ny oppdatering!',
    'Woohoo - we released a new update 🥳':
        'Jippi - vi har sluppet en ny oppdatering 🥳',
    Greetings: 'Hilsen',
    WOOHOO: 'JIPPI',
    'Congratulations on a successful project! Well done':
        'Gratulerer med vel gjennomført prosjekt! Godt jobba',
    'Completed project': 'Fullført prosjekt',
    'What do you want to do?': 'Hva vil du gjøre?',
    'Upload pattern': 'Last opp oppskrift',
    'Add new category': 'Legg til ny kategori',
    'Add new subcategory': 'Legg til ny underkategori',
    'No yarn found': 'Ingen garn funnet',
    'You have no yarn in the stash right now':
        'Du har ikke noe garn på lager akkurat nå',
    'Would you like to return the yarn in this project to the yarn stash':
        'Vil du returnere garnet i dette prosjektet til garnlageret',
    'Do not return': 'Ikke returner',
    'Duplicate!': 'Duplisert!',
    '{{projectName}} was duplicated into an active project':
        '{{projectName}} ble duplisert til et aktivt prosjekt',
    '{{projectName}} was duplicated into a planned project':
        '{{projectName}} er ble duplisert til et planlagt prosjekt',
    '{{count}} completed projects 🎉': '{{count}} fullførte prosjekter 🎉',
    '1 completed project 🎉': '1 fullført prosjekt 🎉',
    '0 completed projects': '0 fullførte prosjekter',
    'Search for project or name': 'Søk etter prosjekt eller navn',
    'Search project details, yarn or designer':
        'Søk prosjektdetaljer, garn eller designer',
    'Select year': 'Velg år',
    'Select month (optional)': 'Velg måned (valgfritt)',
    'You can view completed projects for an entire year or a specific month. Click the calendar icon to try.':
        'Du kan se fullførte prosjekter for et helt år eller en spesifikk måned. Trykk på kalenderikonet for å prøve.',
    // 'Go to More > Overview to see yearly or monthly overview of your yarn consumption 🧶':
    //     'Gå til Mer > Oversikt for å se årlig eller månedlig oversikt av ditt garnforbruk 🧶',
    'Unfollow {{user}}': 'Slutt å følge {{user}}',
    'Follow {{user}}': 'Følg {{user}}',
    Duplicated: 'Duplisert',
    January: 'Januar',
    February: 'Februar',
    March: 'Mars',
    April: 'April',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'Desember',
    'Share completed projects': 'Del fullførte prosjekt!',
    'Go to More > Overview to view an annual or monthly overview of your yarn consumption 🧶':
        'Gå til Mer > Oversikt for å se årlig eller månedlig oversikt av ditt garnforbruk 🧶',
    'Add new row counter': 'Legg til omgangsteller',
    'Edit row counter': 'Rediger omgangsteller',
    'Row counter name': 'Navn på omgangsteller',
    'New password saved!': 'Nytt passord lagret!',
    'Error!': 'Feil!',
    'New password': 'Nytt passord',
    'Available in': 'Tilgjengelig på',
    // 'Want to use this pattern?': 'Vil du bruke dette mønsteret?',
    'See all from {{designer}}': 'Se alt fra {{designer}}',
    'Size guide': 'Størrelsesguide',
    'Yarn alternatives': 'Garnalternativ',
    Discover: 'Oppdag',
    'All patterns': 'Alle oppskrifter',
    Favorites: 'Favoritter',
    'Filter and sort': 'Filtrer og sorter',
    'Remove all': 'Fjern alle',
    'No search results for': 'Ingen søkeresultater for',
    'Stash & tools': 'Stæsj & verktøy',
    'My stats': 'Min statistikk',
    'Sharing screenshot': 'Deler skjermbilde',
    "Your device doesn't support sharing": 'Enheten din støtter ikke deling',
    Error: 'Feil',
    'Click the round + button to add your own categories. In each category you can also add subcategories':
        'Trykk på den runde + knappen for å legge til dine egne kategorier. I hver kategori kan du også legge til underkategorier.',
    'Search for patterns, designers and tags':
        'Søk etter oppskrifter, designere og tagger',
    'Pattern used <span>{{count}}</span> time':
        'Oppskrift brukt <span>{{count}}</span> gang',
    'Pattern used <span>{{count}}</span> times':
        'Oppskrift brukt <span>{{count}}</span> ganger',
    '{{count}} patterns': '{{count}} oppskrifter',
    '1 pattern': '1 oppskrift',
    'Start new project with pattern': 'Start nytt prosjekt med oppskrift',
    'Plan new project with pattern': 'Planlegg nytt prosjekt med oppskrift',
    'No patterns in this category. Upload here.':
        'Ingen oppskrifter i denne kategorien. Last opp her.',
    'Edit category': 'Rediger kategori',
    'Category name*': 'Navn på kategori*',
    'Select icon*': 'Velg ikon*',
    'Subcategory name*': 'Navn på underkategori*',
    'Edit subcategory': 'Rediger underkategori',
    'Add link': 'Legg til link',
    'File*': 'Fil*',
    'Loading...': 'Laster...',
    'Change pdf': 'Bytt pdf',
    'Add subcategory': 'Legg til underkategori',
    'Select subcategory': 'Velg underkategori',
    'Delete subcategory': 'Slett underkategori',
    'Pattern name*': 'Oppskriftsnavn*',
    'Designer name*': 'Designernavn*',
    'Category*': 'Kategori*',
    'Drag and drop to reorder': 'Dra for å endre rekkefølge',
    Preview: 'Forhåndsvis',
    'Add images': 'Legg til bilder',
    'Images*': 'Bilder*',
    pages: 'sider',
    page: 'side',
    'Link*': 'Link*',
    'choose from existing tags': 'velg fra eksisterende tagger',
    'e.g. gauge, season, scrap yarn etc.':
        'f.eks. strikkefasthet, sesong, restegarn osv.',
    'Uploading pdf...': 'Laster opp pdf...',
    'Uploading...': 'Laster opp...',
    'Uploading images...': 'Laster opp bilder...',
    'Edit pattern': 'Rediger oppskrift',
    'From Patterns': 'Fra Oppskrifter',
    'Upload new pattern': 'Last opp ny oppskrift',
    'Uploaded!': 'Lastet opp!',
    'The pattern was also added to Patterns.':
        'Oppskriften ble også lagt til i Oppskrifter.',
    'Show Pattern': 'Vis mønster',
    '🎉 YAY!': '🎉 YAY!',
    'New project started!': 'Nytt prosjekt startet!',
    'Swipe left to view the pattern.':
        'Swipe til venstre for å se oppskriften.',
    "Sorry we can't process at this time":
        'Beklager, vi kan ikke behandle dette nå.',
    'was added to a new planned project.':
        'ble lagt til i et nytt planlagt prosjekt.',
    '🎉 All ready!': '🎉 Alt klart!',
    Description: 'Beskrivelse',
    Sizes: 'Størrelser',
    'Show {{count}} patterns': 'Vis {{count}} oppskrifter',
    'Show 1 pattern': 'Vis 1 oppskrift',
    'Share active projects': 'Del aktive prosjekt',
    'Share planned projects': 'Del planlagte prosjekt',
    'Error, Please check your internet connection':
        'Feil, sjekk Internett-tilkoblingen din',
    'Session expired. Please login again to continue':
        'Sesjon utløpt. Logg på igjen for å fortsette',
    'Sort by': 'Sorter etter',
    'Filter by': 'Filtrer etter',
    Alphabetical: 'Alfabetisk',
    Newest: 'Nyeste',
    Popular: 'Populært',
    'For the fall wardrobe': 'Til høstgarderoben',
    'For the winter wardrobe': 'Til vintergarderoben',
    'For the spring wardrobe': 'Til vårgarderoben',
    'For the summer wardrobe': 'Til sommergarderoben',
    Filter: 'Filter',
    'You recently viewed': 'Du har nylig sett på',
    'Upgrade your subscription': 'Oppgrader abonnement',
    'The ultimate experience': 'Den ultimate opplevelsen',
    'Monthly discounts on yarn or accessories':
        'Månedlige rabatter på garn eller tilbehør',
    'Basic features': 'Basisfunksjoner',
    'Ad-free': 'Reklamefri',
    'Unlimited access to hundreds of patterns':
        'Ubegrenset tilgang til hundrevis av oppskrifter',
    'A little extra': 'Det lille ekstra',
    'Premium Lite': 'Premium Lite',
    'Continue with the free version or upgrade to enjoy our premium features.':
        'Fortsett med gratisversjonen eller oppgrader for å utforske våre premiumfunksjoner.',
    'The basics': 'Det grunnleggende',
    'If you downgrade from premium to premium lite, you’ll lose access to the patterns in K&N’s library.':
        'Dersom du nedgraderer fra premium til premium lite mister du tilgang til oppskriftene i K&N sitt bibliotek.',
    'If you downgrade to the free version, you’ll keep all basic feautures but lose the premium benefits.':
        'Dersom du nedgraderer til gratisversjonen beholder du alle basisfunksjoner, men du mister premiumfordelene.',
    'Could not start project because of missing pattern id, status or selected language':
        'Kunne ikke starte prosjektet på grunn av manglende oppskrift-ID, status eller valgt språk',
    'Could not start project because of missing designer':
        'Kunne ikke starte prosjektet på grunn av manglende designer',
    'Could not start project because of an issue with project response':
        'Kunne ikke starte prosjektet på grunn av et problem med prosjektsvar',
    'Could not start project with this pattern at this time':
        'Kunne ikke starte prosjektet med denne oppskriften nå',
    'Since January 2024 your subscription is named Premium lite. Unlock a world of high-quality patterns by upgrading to the new Premium tier.  Click "Manage" or select Premium below.':
        'Siden januar 2024 har abonnementet ditt endret navn til Premium Lite. Lås opp en verden av oppskrifter med høy kvalitet ved å oppgradere til det nye Premium-nivået. Velg "administrer" eller oppgrader til Premium nedenfor.',
    Beginner: 'Nybegynner',
    'Search pattern, designer or gauge':
        'Søk oppskrifter, designere, strikkefasthet',
    'Click to upgrade and get access to hundreds of patterns!':
        'Klikk for å oppgradere og få tilgang til hundrevis av oppskrifter!',
    'Upgrade to Premium here and get access to hundreds of patterns!':
        'Oppgrader til Premium her og få tilgang til hundrevis av oppskrifter!',
    Ad: 'Annonse',
    benefits: 'fordeler',
    'Upgrade now': 'Oppgrader nå',
    'No thank you': 'Nei takk',
    // 'Plan your next project in the library with Premium':
    //     'Planlegg ditt neste prosjekt i biblioteket med Premium',
    // 'Easy access to hundreds of patterns, with no ads':
    //     'Enkel tilgang til hundrevis av oppskrifter, og null reklame',
    // 'Fixed discounts on yarn and accessories in the K&N store coming in 2024':
    //     'Faste rabatter på garn og tilbehør i K&N butikken som kommer i 2024',
    // 'Gift card on a K&N totebag that can be used when the store opens':
    //     'Gavekort på en K&N totebag som kan veksles inn når butikken kommer',
    // 'Start your next project in the library with Premium':
    //     'Start ditt neste prosjekt i biblioteket med Premium',
    'In need of new inspiration': 'Trenger du ny inspirasjon',
    // 'Upgrade to Premium for easy access to hundreds of patterns, with no ads':
    //     'Oppgrader til Premium for enkel tilgang til hundrevis av oppskrifter, og null reklame',
    // 'Yarn shop launching in the app in 2024':
    //     'Garnbutikk kommer i appen i 2024',
    // 'Get ready to stock up your yarn stash with top brands and our very own K&N yarn:':
    //     'Gled deg til å fylle garnlageret med toppmerker og våre helt egne K&N garn:',
    'Cashmere Mohair': 'Cashmere Mohair',
    // 'Super soft and fluffy mohair yarn with 15% cashmere':
    //     'Supermykt og fluffy mohair garn med 15% kasjmir',
    Merino: 'Merino',
    // '100% natural merino wool. Soft and comfortable on your skin':
    //     '100% ubehandlet merinoull. Mykt og behagelig på huden',
    'Highland Wool': 'Highland Wool',
    // '100% pure wool, delightful to knit with, and ensures a beautiful stitch definition':
    //     '100% ull som er deilig å strikke med og gir et jevnt maskebilde',
    // 'Upgrade to Premium for an ad-free experience, and receive a gift card for a K&N tote*':
    //     'Oppgrader til Premium for en reklamefri opplevelse, og få gavekort på K&N totebag *',
    // '*The gift card can be used when the K&N store opens in the app in 2024':
    //     '*Gavekortet kan veksles inn når K&N butikken kommer i appen i 2024',
    'Favorite patterns': 'Favorittoppskrifter',
    'Gift card for a K&N tote bag*': 'Gavekort på K&N totebag*',
    'Favorite designers': 'Favorittdesignere',
    'You have no favorites. Press the heart next to a pattern or designer to add it as a favorite.':
        'Du har ingen favoritter. Trykk på hjertet ved en oppskrift eller designer for å legge den til som favoritt.',
    Shop: 'Butikk',
    'Search for video tutorials': 'Søk etter hjelpevideo',
    video: 'video',
    videos: 'videoer',
    'No data found!': 'Fant ikke data!',
    'Video not found!': 'Fant ikke video!',
    // '*Can be used when store opens in 2024':
    //     '*Veksles inn når butikken kommer i 2024',
    // 'Unlimited access to patterns': 'Ubegrenset tilgang til oppskrifter',
    // 'Monthly discounts on yarn and accessories':
    //     'Månedlige rabatter på garn eller tilbehør',
    'Ad-free experience': 'Reklamefri opplevelse',
    'Upgrade to Premium': 'Oppgrader til Premium',
    'Close ad': 'Lukk annonse',
    'Community Inspiration': 'Inspirasjon fra Fellesskapet',
    'Exclusive prices on all products in the shop':
        'Eksklusive priser på alle produkter i butikken',
    'Minimum 15% off everything from Knit&Note':
        'Minst 15% rabatt på alt fra Knit&Note',
    'Organize your projects, patterns and stash, + helpful tools and video tutorials':
        'Organiser dine prosjekter, oppskrifter og strikkestæsj + nyttige verktøy og hjelpevideoer',
    Country: 'Land',
    'No ads': 'Ingen reklame',
    'Free use of all the patterns': 'Fri bruk av alle oppskriftene',
    'Free use of more than 1 000 patterns with focus on Scandinavian design':
        'Fri bruk av over 1 000 oppskrifter fra mange kjente og flinke designere',
    'No Premium?': 'Ingen Premium?',
    "You're missing out on something <span>BIG</span>":
        'Du går glipp av noe <span>STORT</span>',
    "Don't miss out on this": 'Ikke gå glipp av dette',
    'Get more with Premium': 'Få mer med Premium',
    'Upgrade to Premium for free use of more than 1 000 patterns':
        'Oppgrader til Premium for fri bruk av over 1 000 oppskrifter',
    'Time to restock your yarn stash?': 'På tide med påfyll i garnlageret?',
    'To the candy shop': 'Til godteributikken',
    '3 reasons why you should <span>NOT</span> upgrade to Premium':
        '3 grunner til at du <span>IKKE</span> bør oppgradere til Premium',
    "You won't have to choose from all the beautiful patterns in the library":
        'Du slipper å velge blant alle de fine oppskriftene i biblioteket ',
    'You get to pay standard price in the shop and you avoid saving money':
        'Du får betale standardpris i butikken og unngår å spare penger',
    "You'll miss out on the pretty ads":
        'Du går glipp av all den fine reklamen',
    'Continue as before': 'Fortsett som før',
    'Upgrade anyway': 'Oppgrader likevel',
    'Hi there 👋🏻': 'Hei 👋🏻',
    'Please set the country you are in, so that you get correct prices and shipping options in the Shop 😍':
        'Velg landet du er i slik at du får riktige priser og fraktalternativer i Butikken 😍',
    'Want to knit this beautiful sweater? 🤗':
        'Vil du strikke denne nydelige genseren? 🤗',
    'Show me 🔥': 'Vis meg 🔥',
    'Which color are you choosing? 🫶🏻': 'Hvilken farge velger du? 🫶🏻',
    'Find pattern here': 'Finn oppskriften her',
    'How about a new dress? 😍': 'Hva med en ny kjole? 😍',
    'Perfect for layering over dresses 🌸': 'Perfekt over kjoler 🌸',
    'video tutorials': 'hjelpevideoer',
    All: 'Alle',
};

export type TranslatableString = keyof typeof noTranslations;

export default noTranslations;
